/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ModalProgressBar } from "..";
import { Button, Modal } from "react-bootstrap";
import FlashMessages from "../extras/FlashMessages";
import { ControlUIProvider } from "../store";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";

const FormModal = ({
  children,
  size = "md",
  btnText = "GENERAL.SAVE",
  success = false,
  error,
  onClose,
  title,
  subtitle,
  onHide,
  onSuccess,
  show,
  isLoading,
  showFooter = true,
  disable_hide_modal = false,
  cunciltooltipText
}) => {
  const saveRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  // if !id we should close modal
  useEffect(() => {
    if (success && show && submitting) {
      setSubmitting(false);
      onHide();
      onSuccess && onSuccess();
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, show]);

  useEffect(() => {
    if (success) {
      onHide();
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const onSave = () => {
    if (saveRef && saveRef.current) {
      saveRef.current.click();
      setSubmitting(true);
    }
  };

  return (
    <ControlUIProvider>
      <Modal
        centered
        size={size}
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar variant="query" />}
        <Modal.Header className="border-0 pb-0">
          {title && (
            <Modal.Title id="example-modal-sizes-title-lg">
              <div
                style={{
                  color: "#29366A",
                  fontSize: "1.5rem",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "2.25rem",
                }}
                className="Work-Sans"
              >
                {title}
              </div>
            </Modal.Title>
          )}
          {subtitle && (
            <Modal.Title id="example-modal-sizes-title-lg">
              <div
                style={{
                  color: "#303030",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: 700,
                }}
                className="Open Sans"
              >
                {subtitle}
              </div>
            </Modal.Title>
          )}
          {!disable_hide_modal &&
            <CloseIcon
              style={{ color: "#979797" }}
              type="button"
              disabled={disable_hide_modal}
              onClick={onHide}
            />}
        </Modal.Header>
        <Modal.Body className="border-0">
          {onClose && <FlashMessages error={error} onClose={onClose} />}
          {children({ saveRef })}
        </Modal.Body>
        {showFooter && (
          <Modal.Footer className="border-0">
            <div
              className="d-flex flex-row align-items-start"
              style={{ gap: "10px" }}
            >

              {cunciltooltipText ? <Tooltip title={<FormattedMessage id={cunciltooltipText} />}>

                <Button
                  type="button"
                  onClick={onHide}
                  className={disable_hide_modal ? "btn-sm  btn-secondary" : "btn-sm outline-button"}
                  disabled={disable_hide_modal}
                >
                  <FormattedMessage id="GENERAL.CANCEL" />
                </Button>
              </Tooltip> : <Button
                type="button"
                onClick={onHide}
                className={disable_hide_modal ? "btn-sm  btn-secondary" : "btn-sm outline-button"}
                disabled={disable_hide_modal}
              >
                <FormattedMessage id="GENERAL.CANCEL" />
              </Button>}
              <Button
                type="button"
                disabled={isLoading}
                onClick={onSave}
                className="btn-sm btn-primary btn-elevate"
              >
                {isLoading && (
                  <span className="px-5 spinner spinner-white"></span>
                )}
                <FormattedMessage id={btnText} />
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </ControlUIProvider>
  );
};

export default FormModal;
