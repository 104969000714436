import { ACTIONS } from "../constants"


const initialState = { 
  vaccinationCount:0,
  allvaccination:0,
  mapData:[],
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {
 

    case ACTIONS.FETCH_VACCINATION_COUNT_INIT : {
      return { ...state, isLoading: true, structure: null, error: null,vaccinationCount:0,  allvaccination:0,mapData:[] }
    }
    case ACTIONS.FETCH_VACCINATION_COUNT_SUCCEDED : {
      const {data,totalvaccinesCount,totalvaccinesPasse} = payload
      return { ...state, vaccinationCount: totalvaccinesCount,  allvaccination:totalvaccinesPasse,mapData:data, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_VACCINATION_COUNT_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }


    default: {
      return state
    }
  }
}
