import { ACTIONS } from "../constants"


const initialState = { 
  totalinscritparagent:0,
  totalinscritparcitoyen:0,
  all:0,
  mapData:[],
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {
 

    case ACTIONS.FETCH_CITIZEN_COUNT_INIT : {
      return { ...state, isLoading: true, structure: null, error: null,totalinscritparagent:0,  totalinscritparcitoyen:0,all:0,mapData:[] }
    }
    case ACTIONS.FETCH_CITIZEN_COUNT_SUCCEDED : {
      const {data,totalinscritparagent,totalinscritparcitoyen} = payload
      return { ...state, totalinscritparagent: totalinscritparagent,  totalinscritparcitoyen:totalinscritparcitoyen,mapData:data,all:totalinscritparcitoyen+totalinscritparagent, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_CITIZEN_COUNT_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }


    default: {
      return state
    }
  }
}
