export const STRUCTURES = "/api/structure"
export const STRUCTURE = "/api/structure/:param"


export const CREATECHILDREN = "/api/citizen/create_children"

export const EVAXSEARCH = "/api/citizen/:param/children"

export const CHECK_PARENT = "/api/citizen/middle_office/check_parent"

export const DELETECHILD= "/api/citizen/:param/medical_folder/close/:lang";

export const LINK_CHILD = "/api/citizen/registred/create_children";

export const LINK_FOREIGN_CHILD = "/api/citizen/registered/foreign/create_children";

export const CREATE_FOREIGN_CHILDREN = "/api/citizen/foreign/create_children";

