
export const FETCH_MAPI_INIT = "mapi@FETCH_MAPI_INIT"
export const FETCH_MAPI_SUCCEDED = "mapi@FETCH_MAPI_SUCCEDED"
export const FETCH_MAPI_FAILED = "mapi@FETCH_MAPI_FAILED"

export const FETCH_MAPI_LIST_INIT = "mapi@FETCH_MAPI_LIST_INIT"
export const FETCH_MAPI_LIST_SUCCEDED = "mapi@FETCH_MAPI_LIST_SUCCEDED"
export const FETCH_MAPI_LIST_FAILED = "mapi@FETCH_MAPI_LIST_FAILED" 

export const EDIT_MAPI_INIT = "mapi@EDIT_MAPI_INIT"
export const EDIT_MAPI_SUCCEDED = "mapi@EDIT_MAPI_SUCCEDED"
export const EDIT_MAPI_FAILED = "mapi@EDIT_MAPI_FAILED"

export const DELETE_MAPI_INIT = "mapi@DELETE_MAPI_INIT"
export const DELETE_MAPI_SUCCEDED = "mapi@DELETE_MAPI_SUCCEDED"
export const DELETE_MAPI_FAILED = "mapi@DELETE_MAPI_FAILED"

export const CREATE_NEW_MAPI_INIT = "mapi@CREATE_NEW_MAPI_INIT"
export const CREATE_NEW_MAPI_SUCCEDED = "mapi@CREATE_NEW_MAPI_SUCCEDED"
export const CREATE_NEW_MAPI_FAILED = "mapi@CREATE_NEW_MAPI_FAILED"


export const FETCH_VACCINATIONS_LIST_INIT = "mapi@FETCH_VACCINATIONS_LIST_INIT"
export const FETCH_VACCINATIONS_LIST_SUCCEDED = "mapi@FETCH_VACCINATIONS_LIST_SUCCEDED"
export const FETCH_VACCINATIONS_LIST_FAILED = "mapi@FETCH_ROUTINE_VACCINATIONS_LIST_FAILED"

export const FETCH_VACCINATIONS_LIST_RABIES_INIT = "mapi@FETCH_VACCINATIONS_LIST_RABIES_INIT"
export const FETCH_VACCINATIONS_LIST_RABIES_SUCCEDED = "mapi@FETCH_VACCINATIONS_LIST_RABIES_SUCCEDED"
export const FETCH_VACCINATIONS_LIST_RABIES_FAILED = "mapi@FETCH_VACCINATIONS_LIST_RABIES_FAILED"

export const CLEAR_VACCINATION = "mapi@CLEAR_VACCINATION"
export const CLEAR_MEDICAL_FOLDER = "mapi@CLEAR_MEDICAL_FOLDER"
export const CLEAR_MEDICAL_FOLDER_ERROR = "mapi@CLEAR_MEDICAL_FOLDER_ERROR"


export const CLEAR_MAPI = "mapi@CLEAR_MAPI"