import Item from "./../items/Item"
import ListOfItems from "./../items/ListOfItems"
import NestedListOfItems from "./../items/NestedListOfItems"
import TableOfItems from "./../items/TableOfItems"
import NestedItems from "./../items/NestedItems"
import ImgItem from "./../items/Img"
import FileItem from "./../items/File"
import ItemOff from "./../items/ItemOff"
import ItemFileDisplay from "../items/ItemFileDisplay"

export const ITEM = "display@item"
export const LIST_OF_ITEMS = "display@list_of_items"
export const NESTED_LIST_OF_ITEMS = "display@nested_list_of_items"
export const TABLE_OF_ITEMS  = "display@table_of_items"
export const NESTED_FIELDS = "display@nested_fields"
export const IMG_ITEM = "display@img_item"
export const FILE_ITEM = "display@file_item"
export const ITEM_OFF = "display@item_off"
export const DISPLAY_IMG_PDF_FILE = "display@display_file_pdf_img"



export const DEFAULT_ITEM_OFF = ItemOff
export const DEFAULT_ITEM = Item
export const ITEM_COMPONENT = {
  [ITEM]: Item,
  [DISPLAY_IMG_PDF_FILE]:ItemFileDisplay,
  [ITEM_OFF]: ItemOff,
  [IMG_ITEM]: ImgItem,
  [FILE_ITEM]: FileItem,
  [NESTED_FIELDS]: NestedItems,
  [LIST_OF_ITEMS]: ListOfItems,
  [NESTED_LIST_OF_ITEMS]: NestedListOfItems,
  [TABLE_OF_ITEMS]: TableOfItems
}

