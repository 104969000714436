import React, { useEffect, useMemo, useState } from "react";
import { isArray, isFunction, isEmpty } from "lodash";
import { useIntl } from "react-intl";
import ContentLoader from "react-content-loader";

import DisplayItem from "./DisplayItem";
import { Col } from "react-bootstrap";
import { getAttr } from "../../../../../helpers";
import useHideAndShowField from "./hooks/useHideAndShowField";
import _ from "lodash";

export const ItemLodaer = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={10}
    viewBox="0 0 100% 10"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="10" />
  </ContentLoader>
);

const Item = ({ field, object, disabledStyle }) => {
  const intl = useIntl();

  const {
    name,
    options,
    className="",
    loadOptions,
    label,
    html = false,
    icon = undefined,
    size = 12,
    formatter = null,
    onClick = null,
    hide = false,
  } = field;

  const conditionalShowAndHideClassName = useHideAndShowField({
    object,
    ...field,
  });

  const [loadedOptions, setLoadedOptions] = useState();

  useEffect(() => {
    if (isFunction(loadOptions) && isEmpty(loadedOptions)) {
      loadOptions(setLoadedOptions);
    }

    // eslint-disable-next-line
  }, [loadOptions]);

  const value = useMemo(() => {
    const attrValue = getAttr(
      object,
      name,
      intl.formatMessage({ id: "GENERAL.EMPTY" })
    );
    if (isArray(options) && !isArray(attrValue)) {
      const selectedOption = options.find(
        (option) => option.value === attrValue
      );
      return (
        (selectedOption && selectedOption.label) ||
        intl.formatMessage({ id: "GENERAL.EMPTY" })
      );
    } else if (isArray(attrValue) && isArray(attrValue) && isArray(options)) {
      const selectedOptions = options.filter((option) =>
        attrValue.includes(option.value)
      );
      return isArray(selectedOptions)
        ? selectedOptions.map((val) => val.label).join(", ")
        : intl.formatMessage({ id: "GENERAL.EMPTY" });
    } else if (isArray(loadedOptions) && !isArray(attrValue)) {
      const selectedOption = loadedOptions.find(
        (option) => option.value === attrValue
      );
      return (
        (selectedOption && selectedOption.label) ||
        intl.formatMessage({ id: "GENERAL.EMPTY" })
      );
    } else if (isArray(loadedOptions) && isArray(attrValue)) {
      const selectedOptions = loadedOptions.filter((option) =>
        attrValue.includes(option.value)
      );
      return isArray(selectedOptions)
        ? selectedOptions.map((val) => val.label).join(", ")
        : intl.formatMessage({ id: "GENERAL.EMPTY" });
    }

    return attrValue;
    // eslint-disable-next-line
  }, [object, loadedOptions]);

  return (
    <>
      {!hide && (
        <Col lg={size} className={conditionalShowAndHideClassName}>
          <DisplayItem
            className={className}
            primary={label}
            html={html}
            icon={icon}
            onClick={onClick}
            disabledStyle={disabledStyle}
            secondary={
              !_.isNull(formatter) ? (
                formatter(value, object)
              ) : !isEmpty(object) ? (
                value || intl.formatMessage({ id: "GENERAL.EMPTY" })
              ) : (
                <ItemLodaer />
              )
            }
          />
        </Col>
      )}
    </>
  );
};

export default Item;
