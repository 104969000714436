import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"
import { lazy } from "react"
import { CREATE, LIST, SEARCH, VIEW } from "src/constants"
import { CHILDREN, MAPI, VACCINATION } from "src/constants/AppPermissions"
import {  MedicalFolderManagement, VaccinationManagement } from "src/constants/ModulesPermissions"



const MapiSearch = lazy(() => import("../components/card/MapiSearch"))
const MapiPage = lazy(() => import("../MapiPage"))




export const mapiPage = {
  path: "/mapi/:param([0-9]{9})",
  component: MapiPage,
  can: MedicalFolderManagement.module[MAPI].permissions[VIEW]

}
export const mapiSearch = {
  path: "/mapi",
  component: MapiSearch,
  exact:true,
  can: MedicalFolderManagement.module[MAPI].permissions[VIEW]

}




const path = routes.admin.path

export default combinePathRoutes({ path }, {
  mapiPage,
  mapiSearch
})
