// structure
export const FETCH_STRUCTURES_INIT = "structure@FETCH_STRUCTURES_INIT"
export const FETCH_STRUCTURES_SUCCEDED = "structure@FETCH_STRUCTURES_SUCCEDED"
export const FETCH_STRUCTURES_FAILED = "structure@FETCH_STRUCTURES_FAILED"

export const CLEAR_STRUCTURE_FORM = "structure@CLEAR_STRUCTURE_FORM"

export const CREATE_STRUCTURE_INIT = "structure@CREATE_STRUCTURE_INIT"
export const CREATE_STRUCTURE_SUCCEDED = "structure@CREATE_STRUCTURE_SUCCEDED"
export const CREATE_STRUCTURE_FAILED = "structure@CREATE_STRUCTURE_FAILED"

export const FETCH_STRUCTURE_INIT = "structure@FETCH_STRUCTURE_INIT"
export const FETCH_STRUCTURE_SUCCEDED = "structure@FETCH_STRUCTURE_SUCCEDED"
export const FETCH_STRUCTURE_FAILED = "structure@FETCH_STRUCTURE_FAILED"

export const EDIT_STRUCTURE_INIT = "structure@EDIT_STRUCTURE_INIT"
export const EDIT_STRUCTURE_SUCCEDED = "structure@EDIT_STRUCTURE_SUCCEDED"
export const EDIT_STRUCTURE_FAILED = "structure@EDIT_STRUCTURE_FAILED"

export const DELETE_STRUCTURE_INIT = "structure@DELETE_STRUCTURE_INIT"
export const DELETE_STRUCTURE_SUCCEDED = "structure@DELETE_STRUCTURE_SUCCEDED"
export const DELETE_STRUCTURE_FAILED = "structure@DELETE_STRUCTURE_FAILED"

// Address
export const FETCH_ADDRESSES_INIT = "address@FETCH_ADDRESSESES_INIT"
export const FETCH_ADDRESSES_SUCCEDED = "address@FETCH_ADDRESSES_SUCCEDED"
export const FETCH_ADDRESSES_FAILED = "address@FETCH_ADDRESSES_FAILED"


//create children

export const FETCH_CREATECHILDREN_INIT = "address@FETCH_CREATECHILDREN_INIT"
export const FETCH_CREATECHILDREN_SUCCEDED = "address@FETCH_CREATECHILDREN_SUCCEDED"
export const FETCH_CREATECHILDREN_FAILED = "address@FETCH_CREATECHILDREN_FAILED"


export const FETCH_EVAXSEARCH_INIT = "search@FETCH_EVAXSEARCH_INIT"
export const FETCH_EVAXSEARCH_SUCCEDED = "search@FETCH_EVAXSEARCH_SUCCEDED"
export const FETCH_EVAXSEARCH_FAILED = "search@FETCH_EVAXSEARCH_FAILED"

export const CLEAR_CHILDREN = "child@CLEAR_CHILDREN"

export const CHECK_PARENT_INIT = "search@FETCH_EVAXSEARCH_INIT"
export const CHECK_PARENT_SUCCEDED = "search@CHECK_PARENT_SUCCEDED"
export const CHECK_PARENT_FAILED = "search@CHECK_PARENT_FAILED"

export const DELETE_CHILD_INIT = "child@DELETE_CHILD_INIT" 
export  const DELETE_CHILD_SUCCEDED = "child@DELETE_CHILD_SUCCEDED"
export const DELETE_CHILD_FAILED = "child@DELETE_CHILD_FAILED"

export const LINK_CHILD_INIT = "child@LINK_CHILD_INIT"
export const LINK_CHILD_SUCCEDED = "child@LINK_CHILD_SUCCEDED"
export const LINK_CHILD_FAILED = "child@LINK_CHILD_FAILED"

export const LINK_FOREIGN_CHILD_INIT = "child@LINK_FOREIGN_CHILD_INIT"
export const LINK_FOREIGN_CHILD_SUCCEDED = "child@LINK_FOREIGN_CHILD_SUCCEDED"
export const LINK_FOREIGN_CHILD_FAILED = "child@LINK_FOREIGN_CHILD_FAILED"


export const FETCH_CREATE_FOREIGN_CHILDREN_INIT = "address@FETCH_CREATE_FOREIGN_CHILDREN_INIT"
export const FETCH_CREATE_FOREIGN_CHILDREN_SUCCEDED = "address@FETCH_CREATE_FOREIGN_CHILDREN_SUCCEDED"
export const FETCH_CREATE_FOREIGN_CHILDREN_FAILED = "address@FETCH_CREATE_FOREIGN_CHILDREN_FAILED"

