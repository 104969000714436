import {CALL_API, HTTP_METHODS } from "src/constants"
import { ACTIONS, ENDPOINTS } from "../constants"
// import { CALL_API, HTTP_METHODS } from "./../../../../"


export const clearChildren = () =>  ({
  type: ACTIONS.CLEAR_CHILDREN
})

export const fetchAddresses = ({ keyWord }) =>
  ({
    type: CALL_API,
    meta: {
      actions: {
        init: ACTIONS.FETCH_ADDRESSES_INIT,
        success: ACTIONS.FETCH_ADDRESSES_SUCCEDED,
        fail: ACTIONS.FETCH_ADDRESSES_FAILED
      },
      params: { keyWord },
      endpoint: ENDPOINTS.ADDRESS,
      method: HTTP_METHODS.GET,
      auth: true
    }
  })

  export const createChildren = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.FETCH_CREATECHILDREN_INIT,
        success: ACTIONS.FETCH_CREATECHILDREN_SUCCEDED,
        fail: ACTIONS.FETCH_CREATECHILDREN_FAILED
      },
      endpoint: ENDPOINTS.CREATECHILDREN,
      method: HTTP_METHODS.POST,
      auth: true,
    }

  })


  export const createForeignChildren = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.FETCH_CREATE_FOREIGN_CHILDREN_INIT,
        success: ACTIONS.FETCH_CREATE_FOREIGN_CHILDREN_SUCCEDED,
        fail: ACTIONS.FETCH_CREATECHILDREN_FAILED
      },
      endpoint: ENDPOINTS.CREATE_FOREIGN_CHILDREN,
      method: HTTP_METHODS.POST,
      auth: true,
    }

  })

  export const linkChild = (payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.LINK_CHILD_INIT,
      success: ACTIONS.LINK_CHILD_SUCCEDED,
      fail: ACTIONS.LINK_CHILD_FAILED
    },
    endpoint: ENDPOINTS.LINK_CHILD,
    method: HTTP_METHODS.POST,
    auth: true
  }
})

 export const linkForeignChild = (payload) =>
 ({
   type: CALL_API,
   payload,
   meta: {
     actions: {
       init: ACTIONS.LINK_FOREIGN_CHILD_INIT,
       success: ACTIONS.LINK_FOREIGN_CHILD_SUCCEDED,
       fail: ACTIONS.LINK_FOREIGN_CHILD_FAILED
     },
     endpoint: ENDPOINTS.LINK_FOREIGN_CHILD,
     method: HTTP_METHODS.POST,
     auth: true
   }
 })
export const fetchEvaxSearch = (param,params) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_EVAXSEARCH_INIT,
      success: ACTIONS.FETCH_EVAXSEARCH_SUCCEDED,
      fail: ACTIONS.FETCH_EVAXSEARCH_FAILED
    },
    endpoint: ENDPOINTS.EVAXSEARCH.replace(":param", param),
    method: HTTP_METHODS.GET,
    params,
    auth: true
  }
})

export const deleteChild = (params,lang) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.DELETE_CHILD_INIT,
      success: ACTIONS.DELETE_CHILD_SUCCEDED,
      fail: ACTIONS.DELETE_CHILD_FAILED
    },
    endpoint: ENDPOINTS.DELETECHILD.replace(":param",params).replace(":lang",lang),
    method: HTTP_METHODS.DELETE,
    auth: true
  }
})


export const checkParent = (payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CHECK_PARENT_INIT,
      success: ACTIONS.CHECK_PARENT_SUCCEDED,
      fail: ACTIONS.CHECK_PARENT_FAILED
    },
    endpoint: ENDPOINTS.CHECK_PARENT,
    method: HTTP_METHODS.POST,
    auth: true
  }
})
