/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../controls";
import _ from "lodash";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import { isRLTLang } from "./../../../../i18n";
import Divider from "@mui/material/Divider";
import { Button } from "react-bootstrap";
import DownloadItems from "../download-pdf/DownloadItems";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { ControlUIProvider } from "../store";
import { MenuItem, IconButton } from "@material-ui/core";
import StyledMenu from "./StyledMenu";
import LayoutHeader from "../forms/table/LayoutHeader";
import { ProtectedLink } from "src/components/wrappers";
import { CitizenManagement } from "src/constants/ModulesPermissions";
import { CHILDREN } from "src/constants/AppPermissions";
import { CLOSE } from "src/constants";
const ShowView = ({
  avatar,
  children,
  title,
  goBackTo,
  goToEdit,
  print = false,
  printURL,
  toolBar,
  isFetching,
  addBtn,
  show,
  className = "",
  showAlertDialog,
  onClick,
  customClassName = "",
  disabled = false,
  disabledStyle,
}) => {
  const titleColor = avatar ? "#000000" : "#01387d";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <ControlUIProvider>
      <Card
        className={className + `${disabled ? disabledStyle : ""}`}
        onClick={onClick}
      >
        {isFetching && <ModalProgressBar />}
        <CardHeader
          className={`${customClassName}`}
          title={<div style={{ color: titleColor }}>{title}</div>}
          icon={avatar && avatar}
        >
          <CardHeaderToolbar>
            {goBackTo && (
              <Button
                type="button"
                onClick={goBackTo}
                className="btn btn-sm btn-light mx-2"
              >
                {isRLTLang() ? (
                  <>
                    <FormattedMessage id="GENERAL.BACK" />
                    <i className="fa fa-arrow-left" />
                  </>
                ) : (
                  <>
                    <i className="fa fa-arrow-left" />
                    <FormattedMessage id="GENERAL.BACK" />
                  </>
                )}
              </Button>
            )}
            {_.isFunction(addBtn) && (
              <LayoutHeader
                className="p-0"
                goTo={addBtn}
                buttonText={<FormattedMessage id="GENERAL.ADD" />}
              />
            )}
            {_.isFunction(goToEdit) && !disabled && (
              <div>
              <IconButton
                  className="p-1"
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <StyledMenu
                  className="card-edit-menu"
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  open={open}
                  onClose={handleClose}
                >
                  {/* <MenuItem
                    onClick={(event) => {
                      handleClose(event);
                      // show();
                    }}
                    disableRipple
                  >
                    
                    <AccessTimeFilledIcon />
                    <div>
                      
                      <FormattedMessage id="VACCINE.REPORT" />
                    </div>
                  </MenuItem> */}
                  <Divider />
                  <ProtectedLink
                    rule={{
                      can: CitizenManagement.module[CHILDREN].permissions[
                        CLOSE
                      ],
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        showAlertDialog();
                      }}
                      disableRipple
                    >
                      <DeleteForeverIcon />
                      <div>
                        <FormattedMessage id="VACCINE.CLOSE.FILE" />
                      </div>
                    </MenuItem>
                  </ProtectedLink>
                </StyledMenu>
              </div>
            )}
            {print && <DownloadItems title={title} printURL={printURL} />}
            {toolBar}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody
          className={`${customClassName}` + `${disabled ? disabledStyle : ""}`}
        >
          {" "}
          {children}
        </CardBody>
      </Card>
    </ControlUIProvider>
  );
};
export default ShowView;
