/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react"
import { FormattedMessage } from "react-intl"
import { Card, CardBody, CardHeader, CardHeaderToolbar, ModalProgressBar } from "../../controls"
import _ from "lodash"
import { isRLTLang } from "./../../../../i18n"
import { Button } from "react-bootstrap"
import { toAbsoluteUrl } from "src/helpers"
import SVG from "react-inlinesvg";


const ShowView = ({ children, title, goBackTo, goToEdit, goToShow, basePath, toolBar, isFetching, onClose }) => {

  return (
    <Card>
      {isFetching && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          {goBackTo && <Button
            type="button"
            onClick={goBackTo}
            className="btn btn-sm btn-light mx-2"
          >
            {isRLTLang() ?
              <>
                <FormattedMessage id="GENERAL.BACK" />
                <i className="fa fa-arrow-left" />
              </>
              : <>
                <i className="fa fa-arrow-left" />
                <FormattedMessage id="GENERAL.BACK" />
              </>
            }
          </Button>
          }
          {onClose && <a
            className="btn btn-sm btn-hover-danger btn-sm center"
            onClick={onClose}
          >
            <span
             className="svg-icon svg-icon-md svg-icon-secondary"
            >
              <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Navigation/Close.svg"
              )}
            />
            </span>
          </a>}

          {_.isFunction(goToEdit) && <Button
            type="button"
            className="btn btn-sm btn-warning btn-hover-warning mx-3 my-1 "
            onClick={() => goToEdit(basePath)}
          >
            <FormattedMessage id="GENERAL.EDIT" />
          </Button>}
          {_.isFunction(goToShow) && <Button
            type="button"
            className="btn btn-sm btn-warning btn-hover-warning mx-3 my-1 "
            onClick={() => goToShow(basePath)}
          >
            <FormattedMessage id="GENERAL.SHOW" />
          </Button>}
          {toolBar}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {children}
      </CardBody>
    </Card>
  )
}


export default ShowView
