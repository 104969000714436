/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react"
import { FormattedMessage } from "react-intl"
import { ModalProgressBar } from "../../controls"

import { Button, Modal } from "react-bootstrap"
import DownloadItems from "../download-pdf/DownloadItems"
import { ControlUIProvider } from "../store"
import CloseIcon from '@mui/icons-material/Close';
import { isRLTLang } from "src/i18n"
const ShowModal = ({ children, size = "lg", isLoading, print = false, toolBar, printURL, title, onHide, show, onShow, btnText, subtitle, onReject, onValidate, validateButtonText, rejectButtonText }) => {

  return (
    <ControlUIProvider>
      <Modal

        centered
        show={show}
        size={size}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar variant="query" />}
        <Modal.Header className="border-0 pb-0">
          <Modal.Title id="example-modal-sizes-title-lg ">
            {title && <div className="mx-5">{title}</div>}
          </Modal.Title>
          <CloseIcon style={{ color: '#979797' }} type="button" onClick={onHide} />
        </Modal.Header>
        <Modal.Body className={`${subtitle && "pt-0"}`}>
          {subtitle && <Modal.Title id="example-modal-sizes-title-lg" className="pb-11">
            <div className="d-flex justify-content-center">{subtitle}</div>
          </Modal.Title>}
          <div className={`${subtitle && (isRLTLang() ? "pr-10" : "pl-10")}`}>
            {children}
          </div>
        </Modal.Body>
        <Modal.Footer className={`${subtitle && "p-0"} border-0`}>
          <div>
            {btnText && <Button
              type="button"
              onClick={onShow}
              className="custom-outline-btn"
            >
              <FormattedMessage id="PROFILE.UPDATE.DATA" />
            </Button>}
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"

            }}>
              {validateButtonText && <Button
                type="button"
                onClick={onValidate}
                className="outline-button center"
                style={{ float: "left" }}

              >
                <FormattedMessage id={validateButtonText} />
              </Button>}
              {rejectButtonText && <Button
                type="button"
                onClick={onReject}
                style={{ float: "right" }}

                className="custom-outline-btn"
              >
                <FormattedMessage id={rejectButtonText} />
              </Button>}
            </div>

            {print && <DownloadItems title={title} printURL={printURL} />}
            {toolBar}
          </div>
        </Modal.Footer>
      </Modal>
    </ControlUIProvider >
  )
}


export default ShowModal
