import React, { useEffect, useState } from 'react'
import Tab from './Tab';
import './tabStyle.scss'
import {isFunction} from 'lodash';
import { CHILDREN } from 'src/constants/AppPermissions';
const Tabs = ({ children,index,setCurrentTab=null}) => {
  const [activeTab, setActiveTab] = useState(children && children[index] ? children[index].props.label : '');
  

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    if (setCurrentTab) setCurrentTab(...children.map((element,index)=>{
     return element.props.label === tab ? index : null
    }).filter((element)=> element!= null));
  }

  useEffect(() => {
    const filteredChildren = children.filter(element => element !== undefined);
    if (filteredChildren.filter((element)=>element?.props?.label === activeTab)){
        if (isFunction(filteredChildren.find((element)=> element?.props?.label === activeTab)?.props.onClick)) {
          filteredChildren.find((element)=> element?.props?.label === activeTab).props.onClick();
        }
      }
  
    return () => {
      
    }
  }, [activeTab])
  
  if (!children || children.length === 0) {
    return null;
  }
    
  return (
    <div className="tabs">
    <div className="tab-list">
      {children.map((child) => {
         if (!child || !child.props) {
          return null;
        }
        const { label } = child.props;
        console.log({label:label})

        return (
          <Tab
            activeTab={activeTab}
            key={label}
            label={label}
            onClick={onClickTabItem}
          />
        );
      })}
    </div>
    <div className="tab-content">
      {children.map((child) => {
         if (!child || !child.props) {
          return null;
        }
        if (child.props.label !== activeTab) return undefined;
        return child.props.children;
      })}
    </div>
  </div>  )
}

export default Tabs