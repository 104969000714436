import { useFormikContext } from "formik"
import React from "react"
import useHideAndShowField from "./hooks/useHideAndShowField"
import { FormattedMessage } from "react-intl"


const LabelInput = ({
  inputGroupClassName = "form-group",
  field, // { name, value, onChange, onBlur }
  size = 12,
  label,
  ...props
}) => {
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  return (
        <div className={ inputGroupClassName  + conditionalShowAndHideClassName + ` col-lg-${size}`} style={{size : "25px", color : "#7C7C7C",
        fontWeight:500,
        }}>
            <FormattedMessage
             id={label} />
            
    </div> 
  )
}

export default LabelInput
