
export const EVAXSEARCH = "/api/citizen/:param/children"
export const NEW_VACCINATIONS = "/api/vaccination"
export const NEW_OCCATIONAL_VACCINATIONS = "/api/vaccination_occasional"
export const NEW_SEASON_VACCINATIONS = "/api/vaccination_seasonal"
export const NEW_FICHE_ANTIRABIES_VACCINATIONS = "/api/vaccination_antirabique/fiche/create"
export const FETCH_FICHE_ANTIRABIES_VACCINATION = "/api/vaccination_antirabique/fiche/:param/details"
export const EDIT_FICHE_ANTIRABIES_VACCINATION = "/api/vaccination_antirabique/fiche/:param/update"


export const GET_ANTIRABIES_VACCINATION_LIST = "api/vaccination_antirabique/:param/vaccination_list"
export const UPDATE_ANTIRABIES_VACCINATION_SCHEMA = "api/vaccination_antirabique/:param/vaccination_update"

export const VACCINATION_SUPPORTING_FILE = "/api/"
export const VACCINATION = "/api/vaccination/:param"
export const VACCINATION_OCCATIONAL = "/api/vaccination_occasional/:param"
export const VACCINATION_SEASON = "/api/vaccination_seasonal/:param"
export const VACCINATION_POSTPONE = "/api/vaccination/:param/report"

export const VACCINATION_ANTIRABIQUE_DETAILS = "/api/vaccination_antirabique/:param/vaccination_details"

export const VACCINATION_ANTIRABIQUE_LIST = "api/vaccination_antirabique/:param/fiches_list"


export const FETCH_VACCINATIONS_LIST = "/api/vaccination/:param/vaccines_list"
export const FETCH_SEASON_VACCINATIONS_LIST = "/api/vaccination_seasonal/:param/vaccines_list"

export const FETCH_OCCATIONAL_VACCINATIONS_LIST = "/api/vaccination_occasional/:param/vaccines_list"
export const FETCH_ROUTINE_VACCINATIONS_LIST = "/api/vaccination_routine/:param/vaccines_list"

export const CREATE_GROWTH = "/api/medical_folder/:param/growth_metrics/create"
export const FETCH_GROWTH_LIST = "/api/medical_folder/:param/growth_metrics/list?birthDate=:b_d"
export const FETCH_GROWTH_ITEM = "/api/medical_folder/growth_metrics/:id"
export const EDIT_GROWTH_ITEM = "/api/medical_folder/growth_metrics/:id"
export const CREATE_HEAD_CIRCUMFERENCE = "/api/medical_folder/:param/head_circumference_metrics/create?birthDate=:b_d"
export const FETCH_HEAD_CIRCUMFERENCE_LIST = "/api/medical_folder/:param/head_circumference_metrics/list?birthDate=:b_d"
export const FETCH_HEAD_CIRCUMFERENCE = "/api/medical_folder/head_circumference_metrics/:id"
export const EDIT_HEAD_CIRCUMFERENCE = "/api/medical_folder/head_circumference_metrics/:id?birthDate=:b_d"
export const COURBE_GROWTH = "/api/medical_folder/:param/growth_metrics/list?birthDate=:b_d"
export const ALLERGY_LIST = "api/medical_folder/allergies/:param/details"


export const ADD_ALLERGIES = "api/medical_folder/:param/allergies/create"
export const EDIT_ALLERGIES = "api/medical_folder/:param/allergies/update"
export const DOWNLOAD_CALENDAR = "api/get_pdf"
export const DOWNLOAD_PIECE = "api/vaccination/download-file/:param"
