import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import Typography from "@material-ui/core/Typography"
import SVG from "react-inlinesvg"
import { Col } from "react-bootstrap"
import { getAttr, toAbsoluteUrl, toMediaUrl } from "../../../../../helpers"
import useHideAndShowField from "./hooks/useHideAndShowField"

const ItemFileDisplay = ({ field, object }) => {

  const intl = useIntl()

  const { name, type = "pdf", width, height, label, size = 12 } = field

  const conditionalShowAndHideClassName = useHideAndShowField({ object, ...field })

  const value = useMemo(() => {
    return getAttr(object, name, intl.formatMessage({ id: "GENERAL.EMPTY" }))
    // eslint-disable-next-line
  }, [object])

  return (
    <Col lg={size} className={conditionalShowAndHideClassName}>
      <div style={{ display: "flex" }}>
        <Typography component="span" style={{
          fontWeight: 400,
          fontFamily: "Open Sans",
          fontStyle: "normal",
          display: "inline",
          lineHeight: "1.25rem",
          color: "#737373",
          fontSize: ".875rem",
          minWidth: "max-content",
        }}>
          {" "}
          {label}
        </Typography>  {"       "}
        <div>
          {/* {type === "pdf" ? */}
          <iframe src={"https://www.ecam.fr/wp-content/uploads/2016/06/Exemple-fichier-PDF-1.pdf"}
           width={width}
            height={height} />
          <a href="https://www.ecam.fr/wp-content/uploads/2016/06/Exemple-fichier-PDF-1.pdf"
            download={"Exemple-fichier-PDF-1.pdf"}
            target = "_blank"
             className="btn btn-sm btn-icon btn-light btn-hover-primary btn-sm mx-1 my-1"
            >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
            </span>
          </a>
          {/* <img src={value} width={width} height={height} /> */}
          {/* } */}
        </div>
      </div>
    </Col>

  )
}


export default ItemFileDisplay
