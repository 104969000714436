import InputField from "./../inputs/InputField"
import InvisibleRecapcha from "../inputs/InvisibleRecapcha"
import InputMaskField from "./../inputs/InputMaskField"
import TextareaField from "./../inputs/TextareaField"
import SelectField from "./../inputs/SelectField"
import CreatableSelectWithModalField from "./../inputs/CreatableSelectWithModalField"
import CreatableSelectField from "./../inputs/CreatableSelectField"
import AsyncSelectField from "./../inputs/AsyncSelectField"
import CheckboxField from "./../inputs/CheckboxField"
import DatePickerField from "./../inputs/DatePickerField"
import DatePickerFieldWithInput from "./../inputs/DatePickerFieldWithInput"
import WYSIWYGEditorField from "./../inputs/WYSIWYGEditorField"
import UploadField from "./../inputs/UploadField"
import UploadMultipleField from "./../inputs/UploadMultipleField"
import RadioField from "./../inputs/RadioField"
import CheckboxGroupField from "./../inputs/CheckboxGroupField"
import TimePickerField from "./../inputs/TimePickerField"
import MultiSelectTableField from "./../inputs/MultiSelectTableField"
import PreloadedTableField from "./../inputs/PreloadedTableField"
import WeekPickerField from "./../inputs/WeekPickerField"
import InputColorField from "./../inputs/InputColorField"
import FormRepeaterField from "./../inputs/FormRepeater"
import SwitchField from "./../inputs/SwitchField"
import MetricInputField from "./../inputs/MetricInputField"
import InputOtp from "../inputs/inputOtpField"
import CustomSpacer from "../inputs/Spacer"
import BorderBottom from "../inputs/BorderBottom"
import DateRangePickerField from "../inputs/DateRangePickerField"
import CounterField from "./../inputs/CounterField"
import LabelInput from "../inputs/LabelInput"
export const INPUT_METRIC = "input_metric"
export const FORM_REPEATER = "form_repeater"
export const INPUT_COLOR = "input_color"
export const INPUT = "input"
export const MULTIPLE_UPLOAD = "multi_upload"
export const INPUT_MASK = "input_mask"
export const CUSTOM_SPACER ="custom_spacer"
export const BORDER_BOTTOM ="border_bottom"
export const LABEL_INPUT ="label_inout"
export const TEXTAREA = "textarea"
export const ASYNC_SELECT = "async_select"
export const SELECT = "select"
export const SWITCH = "switch"
export const CREATABLE_SELECT = "creatable_select"
export const CREATABLE_SELECT_WITH_MODAL = "creatable_select_with_modal"
export const CHECKBOX = "checkbox"
export const CHECKBOX_GROUP = "checkbox_group"
export const RADIO = "radio"
export const DATE_PICKER = "date_picker"
export const DATE_PICKER_WITH_INPUT = "date_picker_with_input"
export const TIME_PICKER = "time_picker"
export const WYSIWYG_EDITOR = "wysiwyg_editor"
export const UPLOAD = "upload"
export const PRELOADED_TABLE = "preloaded_table"
export const MULTISELECT_TABLE = "multiselect_table"
export const WEEK_PICKER = "week_picker"
export const INPUT_OTP = "input_otp"
export const INVISIBLE_RECAPCHA = "invisible_recapcha"
export const DATE_RANGE_PICKER = "date_range_picker"
export const COUNTER = "counter"

export const DEFAULT_TYPE = InputField
export const FORM_COMPONENT = {
  [INPUT_METRIC]: MetricInputField,
  [FORM_REPEATER]: FormRepeaterField,
  [INPUT_COLOR]: InputColorField,
  [INPUT]: InputField,
  [INPUT_OTP]: InputOtp,
  [TEXTAREA]: TextareaField,
  [SWITCH]: SwitchField,
  [ASYNC_SELECT]: AsyncSelectField,
  [SELECT]: SelectField,
  [CREATABLE_SELECT_WITH_MODAL]: CreatableSelectWithModalField,
  [INPUT_MASK]: InputMaskField,
  [CUSTOM_SPACER]: CustomSpacer,
  [BORDER_BOTTOM]: BorderBottom,
  [LABEL_INPUT]: LabelInput,
  [RADIO]: RadioField,
  [TIME_PICKER]: TimePickerField,
  [CREATABLE_SELECT]: CreatableSelectField,
  [CHECKBOX_GROUP]: CheckboxGroupField,
  [CHECKBOX]: CheckboxField,
  [WYSIWYG_EDITOR]: WYSIWYGEditorField,
  [DATE_PICKER]: DatePickerField,
  [DATE_PICKER_WITH_INPUT]: DatePickerFieldWithInput,
  [UPLOAD]: UploadField,
  [MULTIPLE_UPLOAD]: UploadMultipleField, 
  [PRELOADED_TABLE]: PreloadedTableField,
  [MULTISELECT_TABLE]: MultiSelectTableField,
  [WEEK_PICKER]: WeekPickerField,
  [INVISIBLE_RECAPCHA]: InvisibleRecapcha,
  [DATE_RANGE_PICKER]: DateRangePickerField,
  [COUNTER]: CounterField

}

