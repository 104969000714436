import { lazy } from "react"
import { CREATE, REGISTRED, SEARCH } from "src/constants"
import { CHILDREN } from "src/constants/AppPermissions"
import { CitizenManagement } from "src/constants/ModulesPermissions"
import {combinePathRoutes} from "src/helpers"
import routes from "src/routes"

const ChildrenSpace = lazy(() => import("../ChildrenSpace"))
const ChildAdd = lazy(()=>import("../components/AddChild"))
const ChildLink = lazy(()=>import("../components/LinkChild"))

const AddParent = lazy(()=>import("../components/AddParent"))
const SearchChild = lazy(()=>import("../components/SearchChild.js"))

const childrenSpace = {
  path: "/child/list",
  component: ChildrenSpace,
  exact: true,
}
const parentAdd = {
  path: "/parent/add",
  component: AddParent,
}

const childAdd = {
  path: "/child/add",
  component: ChildAdd,
  can: CitizenManagement.module[CHILDREN].permissions[CREATE]
}

const childLink = {
  path: "/child/link",
  component: ChildLink,
  can: CitizenManagement.module[CHILDREN].permissions[REGISTRED]

}

const searchChild = {
  path: "/child/search",
  component: SearchChild,
  can: CitizenManagement.module[CHILDREN].permissions[SEARCH]

}



const path = routes.admin.path
export default combinePathRoutes({ path }, { childAdd,childLink,parentAdd,childrenSpace,searchChild })
