import { lazy } from "react"
import { VIEW } from "src/constants";
import { DASHBOARD } from "src/constants/AppPermissions";
import { CitizenManagement, VaccinationManagement } from "src/constants/ModulesPermissions";
import { combinePathRoutes } from "src/helpers"
// import ResetPhoneNumber from "src/modules/authentication/containers/ResetPhoneNumber";
import routes from "src/routes"

const Home = lazy(() => import("../Home"))
const ResetPassword = lazy(() => import("../../../../authentication/containers/ResetPassword"));
const ResetPhoneNumber = lazy(() => import("../../../../authentication/containers/ResetPhoneNumber"))
const ProfiledDisplay = lazy(() => import("../components/ProfileDisplay"))

const citoyen = {
  path: "/citoyen",
  component: Home,
  exact: true,
  can: CitizenManagement.module[DASHBOARD].permissions[VIEW]
}

const path = routes.admin.path
export default combinePathRoutes({ path }, {
  citoyen,
})
