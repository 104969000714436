import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import useHideAndShowField from "./hooks/useHideAndShowField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { isRLTLang } from "src/i18n";
import { useIntl } from "react-intl";

export const RE_DIGIT = new RegExp(/^\d+$/);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiToggleButton-sizeSmall": {
      height: "1.25rem",
      width: "3.5652rem",
      background: "#F6F6F6",
      borderRadius: "0.25rem",
      color: "#979797",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
    },
    "& .Mui-selected": {
      background: "#DC3545 !important",
      color: "#F2F2F2 !important",
    },
  },
}));

const DatePickerField = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  inputGroupClassName = "form-group",
  inputClassName = "custom-select d-block w-100",
  format = "HH:mm:XM",
  size = 12,
  required = false,
  disabled= false,
  ...props
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [view, setView] = React.useState("AM");
  const [hour, setHour] = React.useState("");
  const [minute, setMinute] = React.useState("");
  

  const handleChange = (event, nextView) => {
    setView(nextView);
    if (state.minute && state.hour) {
      form.setFieldValue(
        field.name,
        format
          .replace("HH", state.hour)
          .replace("mm", state.minute)
          .replace("XM", nextView)
      );
    }
  };

  const { touched, errors } = form;

  const [state, setState] = useState({ hour: "", minute: "" });

  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name);

  const formik = useFormikContext();
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });

  useEffect(() => {
    if (_.isEmpty(field.value)) {
      // Reset state to initial values if the field value is empty
      setState({ hour: "", minute: "" });
      setView("AM");
    } else if (_.isString(field.value) && field.value.split(":").length === 3) {
      const [hour, minute, period] = field.value.split(":");
      setState({ hour, minute });
      setView(period);
    }
    // eslint-disable-next-line
  }, [field.value]);

 

  const inputHourOnChange = (e) => {
    e.target.setCustomValidity("")
    setState({ ...state, hour: e.target.value.replace(/[^0-9]/g, "") });
    setHour(e.target.value)
  };

  const inputHourOnBlur = (e) => {
    if (hour.length === 2) {
      if (Number(hour) > 12){
        e.target.setCustomValidity(intl.formatMessage({ id: "ERROR.HOUR_FORMAT"}));
      } else {
        if (state.minute && hour) {
          form.setFieldValue(
            field.name,
            format
              .replace("HH", hour)
              .replace("mm", state.minute)
              .replace("XM", view)
          );
        }
        e.target.setCustomValidity("")
      }
    } else {
      e.target.setCustomValidity(intl.formatMessage({ id: "ERROR.HOUR_FORMAT"}));
    }
  }


  const inputMinutesOnChange = (e) => {
    e.target.setCustomValidity("")
    setState({ ...state, minute: e.target.value.replace(/[^0-9]/g, "") });
    setMinute(e.target.value)
  };

  const inputMinutesOnBlur = (e) => {
    if (minute.length === 2) {
      if (Number(minute) > 59){
        e.target.setCustomValidity(intl.formatMessage({ id: "ERROR.HOUR_FORMAT"}));
      } else {
        if (state.hour && minute) {
          form.setFieldValue(
            field.name,
            format
              .replace("HH", state.hour)
              .replace("mm", e.target.value)
              .replace("XM", view)
          );
        }
        e.target.setCustomValidity("")
      }
    } else {
      e.target.setCustomValidity(intl.formatMessage({ id: "ERROR.HOUR_FORMAT"}));
    }
  };

  

  const otpStyle = {
    width: "4rem",
    height: "2.4rem",
    border: ".0625rem solid #D6D6D6",
    background: "#F6F6F6",
    textAlign: "center",
    borderRadius: ".25rem",
  };

  //defaultValue={(currentDate && currentDate.isValid()) ? currentDate.toDate() : undefined}
  return (
    <div
    dir="ltr"
      className={
        inputGroupClassName +
        conditionalShowAndHideClassName +
        ` col-lg-${size}`
      }
    >
      {label && (
        <label className="custom-label-style">
          {" "}
          {isRLTLang() ? (
            <>
              <span>{required && "*"}</span> {label}
            </>
          ) : (
            <>
            {label} <span>{required && "*"}</span>
            </>
          )}
        </label>
      )}
      {/*value={moment(value, format)}*/}
      <div className={`d-flex gaps-6 align-items-start justify-content-${isRLTLang()? "end":"start"}`}>
        <div className="d-flex flex-column ">
          <input
            id="hour"
            onChange={(e) => inputHourOnChange(e)}
            onBlur={(e) => inputHourOnBlur(e)}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{2}"
            maxLength={"2"}
            style={otpStyle}
            value={state.hour}
            disabled={disabled}
            {...props}
            className={` ${fieldCSSClasses}  ${disabled && `disabled`}`}
          />

          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#737373",
            }}
          >
            <FormattedMessage id="GENERAL.HOURS"/>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center fontSize-24 font-weight-bold">
          :
        </div>
        <div className="d-flex flex-column">
          <input
            onChange={(e) => inputMinutesOnChange(e)}
            onBlur={(e) => inputMinutesOnBlur(e)}
            type="text"
            id="minute"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{2}"
            maxLength={"2"}
            style={otpStyle}
            value={state.minute}
            className={` ${fieldCSSClasses} ${disabled && `disabled`}`}
            disabled={disabled}
            {...props}
          />
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#737373",
            }}
          >
                        <FormattedMessage id="GENERAL.MINUTE"/>

          </div>
        </div>

        <ToggleButtonGroup
          className={`${classes.root} ${disabled && `disabled`}`}
          orientation="vertical"
          size="small"
          value={view}
          exclusive
          onChange={handleChange}
          disabled={disabled}
        >
          <ToggleButton size="small" value="AM" aria-label="AM">
          <FormattedMessage id="GENERAL.AM"/>
          </ToggleButton>
          <ToggleButton
            size="small"
            sx={{ size: 5 }}
            value="PM"
            aria-label="PM"
          >
                      <FormattedMessage id="GENERAL.PM"/>

          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <FieldError fieldName={field.name} />
    </div>
  );
};

export default DatePickerField;
