/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader } from "..";
import { ControlUIProvider } from "../store";
import { CircularProgress } from "@mui/material";
import { isRLTLang } from "src/i18n";

const ShowCardView = ({ title, avatar, data, isLoading, className = "" }) => {
  return (
    <ControlUIProvider>
      <Card className={className}>
        <CardHeader
          className={`${!avatar? "d-flex align-items-center justify-content-center" : `p-0 m-0 pt-1 ${isRLTLang() ? "pr-1" : "pl-1"}`}`}
          title={!avatar && title}
          icon={avatar && avatar}
        ></CardHeader>
        <CardBody className="pt-0">
          <div
            style={!avatar ? {
              fontWeight: "bold",
              color: "#E02D39",
              fontSize: "5.5rem",
              lineHeight: "6.5rem",
            }:{
              fontWeight: "bold",
              color: "gray",
              fontSize: "1.25rem",
              lineHeight: "1rem",
              paddingInline: "2rem",
              paddingBottom:"4rem"
            }}
            className="d-flex align-items-center justify-content-center w-100 h-100"
          >
            {isLoading ? (
              <CircularProgress
                size={88}
                thickness={5}
                disableShrink
                color="error"
              />
            ) : avatar ? title : (
              data
            )}
            
          </div>
        </CardBody>
      </Card>
    </ControlUIProvider>
  );
};

export default ShowCardView;
