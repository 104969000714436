import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  FormRepeaterRef,
  FormRepeaterFields,
  FormView,
  LanguageTab,
} from "../../../../../../components/partials/controls";
import { listFieldsAr, listFieldsFr, listNameFieldsAr, listNameFieldsFr,defaultlistFieldsAr,defaultlistFieldsFr } from "./fields/listFields";

import {
  DynamicForm,
  RenderFields,
  DisplayItems,
  RenderItems
} from "../../../../../../components/partials/controls";
import { editList, fetchListExtraData, createList } from "../../store/actions";

// import routes from "../../routes/display";

const ListForm = (props) => {
  const { intl, goBackToList, params = undefined } = props;

  const dispatch = useDispatch();
  const { listToEdit, success, isLoading, isFetching } = useSelector(
    (state) => ({
      success: state.admin.list.success,
      listToEdit: state.admin.list.listExtraData,
      isLoading: state.admin.list.isLoading,
      isFetching: state.admin.list.isFetching,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (!_.isEmpty(params)) {
      dispatch(fetchListExtraData(params));
    }
  }, [params, dispatch]);

  const saveList = (values) => {
    console.table(values)
    if (_.isEmpty(params)) {
      dispatch(createList(values))
    } else {
      dispatch(editList(params, values))
    }

  };

  const fieldsAr = listFieldsAr({ intl });
  const fieldsFr = listFieldsFr({ intl });
  const namefieldsAr = listNameFieldsAr({ intl });
  const namefieldsFr = listNameFieldsFr({ intl });
  const displayfieldsAr = defaultlistFieldsAr({ intl });
  const displayFieldsFr = defaultlistFieldsFr({ intl });



  return (
    <FormView
      list={true}
      goBackTo={goBackToList}
      goToDisplay={!_.isEmpty(params)}
      title={intl.formatMessage({
        id: "LIST.EDIT.TITLE",
      }) + " : " + listToEdit?.nameFr}
      isLoading={isLoading}
    >
      {({ saveRef }) => (
        <LanguageTab>
        { ({ isFr, isAr }) => (<>
        <DynamicForm
          className="mt-5"
          clearValuesAfterSubmit={success.isCreated}
          initialValues={!_.isEmpty(params) && listToEdit}
          onSubmit={saveList}
        >
          <RenderFields fields={namefieldsAr} show={isAr} />
          <RenderFields fields={namefieldsFr} show={isFr} />

          <DisplayItems
          isFetching={isFetching}
          object={listToEdit}
          title={intl.formatMessage({id: "LIST.DEFAULT_OPTIONS"})}
        >
          <RenderItems fields={displayfieldsAr} show={isAr} />
          <RenderItems fields={displayFieldsFr} show={isFr} />
        </DisplayItems>
        <br></br>

          <FormRepeaterRef
            max={100}
            min={1}
            showDeleteButton={false}
            // sortable={true}
            label={intl.formatMessage({ id: "LIST.OPTION" })}
          >
            <FormRepeaterFields fields={fieldsAr} show={isAr} />
            <FormRepeaterFields fields={fieldsFr} show={isFr} />
          </FormRepeaterRef>
          <button ref={saveRef} className="d-none" type="submit"></button>
        </DynamicForm>
        </>)}
        </LanguageTab>
      )}

    </FormView>
  );
};

export default injectIntl(ListForm);

