import { ACTIONS } from "../constants";
import moment from "moment";

function dateDiffInDays(a, b) {

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
 
    let days =  Math.floor((utc2 - utc1) / _MS_PER_DAY);
    
    return days
  }

const initialState = {
 
  newAllergy: {},
  allergyList: [],
  searchResults: [],
  growthList: [],
  graph: {
    data: [],
    label: [],
  },
  filterType: {
    type: '',
    unit:''
  },
  birthDate: '',
  growthItem: {},
  headCircumferenceList: [],
  headCircumferenceItem: {},
  vaccination: {},
  totalSize: 0,
  evaxNumber: null,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  successLabel:'id',
  success: {
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_MEDICAL_FOLDER: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
        growthItem: {},
        headCircumferenceItem: {},
      };
    }

    case ACTIONS.CLEAR_MEDICAL_FOLDER_ERROR: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_GROWTH_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_GROWTH_LIST_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: true,
        growthList: payload.filter((item) => item.date != null),
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_GROWTH_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.COURBE_GROWTH_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.COURBE_GROWTH_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: true,
        graph: { data: payload.filter((item)=>item.date=!null), label: payload.filter((item)=>item.date=!null) },
        payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.COURBE_GROWTH_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_LIST_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: true,
        headCircumferenceList: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.ADD_ALLERGIES_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.ADD_ALLERGIES_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: true,
        success: { ...state.success, isCreated: true },
        allergyList: state.allergyList.concat(payload),
        successLabel:"Votre action est bien enregistrée",
        error: null,
      };
    }
    case ACTIONS.ADD_ALLERGIES_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_ALLERGIES_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.EDIT_ALLERGIES_SUCCEDED: {
      const {allergies,oldRank} = payload
      return {
        ...state,
        isLoading: true,
        success: { ...state.success, isUpdated: true },
        allergyList: [...state.allergyList.filter((item) => 
        !oldRank.includes(item.id)),...allergies],
        successLabel:"Votre action est bien enregistrée",
        error: null,
      };
    }
    case ACTIONS.EDIT_ALLERGIES_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_GROWTH_ITEM_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_GROWTH_ITEM_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: true,
        growthItem: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_GROWTH_ITEM_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.ALLERGY_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.ALLERGY_LIST_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: true,
        allergyList: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.ALLERGY_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_ITEM_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_ITEM_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: true,
        headCircumferenceItem: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_HEAD_CIRCUMFERENCE_ITEM_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

   
    case ACTIONS.CREATE_GROWTH_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_GROWTH_SUCCEDED: {
        let days  = dateDiffInDays(new Date(moment(state.birthDate,"DD/MM/YYYY")),new Date(moment(payload.date,"DD/MM/YYYY")))
        console.log(days)
        payload['age'] = {years:Math.floor(days/365),months:Math.floor((days % 365)/30)}
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        successLabel:"Votre action est bien enregistrée",
        graph: {
          data: state.growthList
            .concat(payload)
            .slice()
            .sort((a, b) => {
              var aa = a.date.split("/").reverse().join(),
                bb = b.date.split("/").reverse().join();
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            })
            .filter((item) => item.date != null),
          label: state.growthList
            .concat(payload)
            .slice()
            .sort((a, b) => {
              var aa = a.date.split("/").reverse().join(),
                bb = b.date.split("/").reverse().join();
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            })
            .filter((item) => item.date != null),
        },
        growthList: state.growthList
          .concat(payload)
          .slice()
          .sort((a, b) => {
            var aa = a.date.split("/").reverse().join(),
              bb = b.date.split("/").reverse().join();
            return aa < bb ? -1 : aa > bb ? 1 : 0;
          })
          .filter((item) => item.date != null),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_GROWTH_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.CREATE_HEAD_CIRCUMFERENCE_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_HEAD_CIRCUMFERENCE_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        successLabel:"Votre action est bien enregistrée",
        headCircumferenceList: state.headCircumferenceList.concat(payload).slice()
        .sort((a, b) => {
          var aa = a.date.split("/").reverse().join(),
            bb = b.date.split("/").reverse().join();
          return aa < bb ? -1 : aa > bb ? 1 : 0;
        }),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_HEAD_CIRCUMFERENCE_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.EDIT_GROWTH_ITEM_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_GROWTH_ITEM_SUCCEDED: {
      let days  = dateDiffInDays(new Date(moment(state.birthDate,"DD/MM/YYYY")),new Date(moment(payload.date,"DD/MM/YYYY")))
      console.log(days)
      payload['age'] = {years:Math.floor(days/365),months:Math.floor((days % 365)/30)}
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        successLabel:"Votre action est bien enregistrée",
        graph: {
            data: state.growthList
            .map((item) => (item.id === payload.id ? payload : item))
            .slice()
            .sort((a, b) => {
              var aa = a.date.split("/").reverse().join(),
                bb = b.date.split("/").reverse().join();
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            }),
            label: state.growthList
            .map((item) => (item.id === payload.id ? payload : item))
            .slice()
            .sort((a, b) => {
              var aa = a.date.split("/").reverse().join(),
                bb = b.date.split("/").reverse().join();
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            }),
          },
        growthList: state.growthList
          .map((item) => (item.id === payload.id ? payload : item))
          .slice()
          .sort((a, b) => {
            var aa = a.date.split("/").reverse().join(),
              bb = b.date.split("/").reverse().join();
            return aa < bb ? -1 : aa > bb ? 1 : 0;
          }),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_GROWTH_ITEM_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.EDIT_HEAD_CIRCUMFERENCE_ITEM_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_HEAD_CIRCUMFERENCE_ITEM_SUCCEDED: {
      return {
        ...state,
        successLabel:"Votre action est bien enregistrée",
        success: { ...state.success, isUpdated: true },
        headCircumferenceList: state.headCircumferenceList.map((item) =>
          item.id === payload.id ? payload : item
        ).slice()
        .sort((a, b) => {
          var aa = a.date.split("/").reverse().join(),
            bb = b.date.split("/").reverse().join();
          return aa < bb ? -1 : aa > bb ? 1 : 0;
        }),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_HEAD_CIRCUMFERENCE_ITEM_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.FILTER_COURBE: {
      return {
        ...state,
        filterType:{type:payload?.type,unit:payload?.unit}
      };
    }
    case ACTIONS.BIRTH_DATE: {
        return {
            ...state,
         birthDate:payload
        };
      }
    case ACTIONS.COURBE_YEAR_MONTH: {
      return { ...state, filterType: { ...state.filterType, unit: payload } };
    }
    default: {
      return state;
    }
  }
};
