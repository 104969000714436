import { combineReducers } from "redux";

// Reducers
import medicalFolderReducer from "./medicalFolder";
import vaccinationReducer from "./vaccination";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  medicalFolder: medicalFolderReducer,
  vaccination: vaccinationReducer,
});
