import { ACTIONS } from "./../constants";

const initialState = {
  newVaccination: {},
  vaccinations: [],
  vaccinesList: [],
  vaccinesRabiesList: [],
  vaccinesOccationalList : [],
  vaccinesSeasonList :[],
  searchResults: [],
  vaccination: {},
  totalSize: 0,
  totalSizeRabies:0,
  evaxNumber: null,
  mapiItem:{},
  mapiItemLength: 0,
  mapiList:[],
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isCreated: false,
    isPostponed: false,
    isUpdated: false,
    isDeleted: false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_VACCINATION: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
        vaccination: {},
        vaccinations: [],
        // vaccinesList: [],
        mapiItem:{},
        // mapiItemLength: 0 ,
        growthItem: {},
        headCircumferenceItem: {},
      };
    }
    case ACTIONS.CLEAR_MAPI: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
        mapiItem: {},
        // mapiItemLength: 0
      };
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_SUCCEDED: {
      // console.log(payload)
      const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        totalSize: count,
        vaccinesList: results,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.CREATE_NEW_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_OCCATIONAL_VACCINATIONS_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }


    case ACTIONS.FETCH_SEASON_VACCINATIONS_LIST_INIT: {
      return { ...state, isFetching: true,  error: null  , vaccinesSeasonList : []};
    }
    case ACTIONS.FETCH_SEASON_VACCINATIONS_LIST_SUCCEDED: {
      // const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        // totalSize: count,
        vaccinesSeasonList: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_SEASON_VACCINATIONS_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_VACCINATIONS_LIST_INIT: {
      return { ...state, isFetching: true,  error: null  , vaccinesSeasonList : []};
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_SUCCEDED: {
      // const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        // totalSize: count,
        ficheAntirabiqueList: payload,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_RABIES_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_RABIES_SUCCEDED: {
      console.log({payload})
      const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        totalSizeRabies: count,
        vaccinesRabiesList: results,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_VACCINATIONS_LIST_RABIES_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    

    case ACTIONS.CREATE_NEW_MAPI_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_NEW_MAPI_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_NEW_MAPI_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.CREATE_NEW_OCCATIONAL_VACCINATIONS_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_NEW_OCCATIONAL_VACCINATIONS_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        vaccinesList: state.vaccinesList.concat(payload),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_NEW_OCCATIONAL_VACCINATIONS_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }



    case ACTIONS.CREATE_NEW_SEASON_VACCINATIONS_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_NEW_SEASON_VACCINATIONS_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        vaccinesList: state.vaccinesList.concat(payload),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_NEW_SEASON_VACCINATIONS_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.EDIT_VACCINATION_OCCATIONAL_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_VACCINATION_OCCATIONAL_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        evaxNumber: payload?.evaxNumber,
        vaccinesList: state.vaccinesOccationalList.map((vaccine) =>
          vaccine.id === payload.vaccine ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_VACCINATION_OCCATIONAL_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.EDIT_VACCINATION_SEASON_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_VACCINATION_SEASON_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        evaxNumber: payload?.evaxNumber,
        vaccinesList: state.vaccinesSeasonList.map((vaccine) =>
          vaccine.id === payload.vaccine ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_VACCINATION_SEASON_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }



    

    case ACTIONS.EDIT_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_VACCINATION_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        evaxNumber: payload?.evaxNumber,
        vaccinesList: state.vaccinesList.map((vaccine) =>
          vaccine.id === payload.vaccine ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_VACCINATION_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.FETCH_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.FETCH_VACCINATION_SUCCEDED: {
      return { ...state, vaccination: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }


  
    case ACTIONS.FETCH_SEASON_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.FETCH_SEASON_VACCINATION_SUCCEDED: {
      return { ...state, vaccination: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_SEASON_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.FETCH_OCCATIONAL_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.FETCH_OCCATIONAL_VACCINATION_SUCCEDED: {
      return { ...state, vaccination: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_OCCATIONAL_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.POSTPONE_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.POSTPONE_VACCINATION_SUCCEDED: {
      return {
        ...state,
        vaccination: null,
        isLoading: false,
        vaccinesList: state.vaccinesList.map((vaccine) =>
          vaccine.id === payload.id
            ? {
                ...vaccine,
                date: payload.date.split("-").reverse().join("/"),
                vaccineDate: payload.date.split("-").reverse().join("/"),
              }
            : vaccine
        ),
        error: null,
        success: { ...state.success, isPostponed: true },
      };
    }
    case ACTIONS.POSTPONE_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.CANCEL_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        success: initialState.success,
        error: null,
      };
    }
    case ACTIONS.CANCEL_VACCINATION_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isDeleted: true },
        isLoading: false,
        vaccinesList: state.vaccinesList.filter(
          (vaccine) => vaccine.id !== payload.id
        ),
        error: null,
      };
    }
    case ACTIONS.DELETE_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }
    case ACTIONS.CLEAR_CHILDREN: {
      return { ...state, isLoading: false, error: null, searchResults: [] };
    }
    case ACTIONS.FETCH_MAPI_LIST_INIT :{
      return { ...state, isFetching: true, error: null };

    }
    case ACTIONS.FETCH_MAPI_LIST_SUCCEDED:{
      const {count,results} = payload
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        mapiList: results,
        totalSize:count,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_MAPI_LIST_FAILED:{
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.FETCH_MAPI_INIT :{
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_MAPI_SUCCEDED :{
      const {medicalFolderDetails , mapiSet} = payload
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        mapiItem: {mapiSet:[...mapiSet],...medicalFolderDetails},
        mapiItemLength:mapiSet?.length,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_MAPI_FAILED : {
      return { ...state, isFetching: false, error: payload };
    }

    default: {
      return state;
    }
  }
};
