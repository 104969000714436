/* eslint-disable no-restricted-imports */
import React from "react"
import { Modal } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { ImportFrom } from ".."
import useDownloader from "../../../../hooks/useDownloader"

const ImportModal = ({ show, title, onHide, url, endpoint, templateURL, onImportFinish }) => {

  const [ isDownloading, downloadTrigger ] = useDownloader({
    endpoint: templateURL || `${(url || endpoint)}/template`, params: undefined, filename: "model.xlsx"
  })

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImportFrom onSubmit={onImportFinish} url={endpoint || url} />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-sm btn-light btn-elevate"
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </button>
        </div>
        <div>
          <button
            type="button"
            disabled={isDownloading}
            onClick={downloadTrigger}
            className="btn btn-sm btn-primary btn-elevate"
          >
            {isDownloading && <span className="px-5 spinner spinner-white" />}
            <FormattedMessage id="GENERAL.DOWNLOAD_TEMPLATE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ImportModal
