// apps
export const CITIZEN_MANAGEMENT = "evax_ii.evaxii.citizen_management";

export const VACCINATION_MANAGEMENT = "evax_ii.evaxii.vaccination_management";
export const LISTS_MANAGEMENT = "evax_ii.evaxii.lists_management";

export const USER_MANAGEMENT = "evax_ii.evaxii.user_managment";

export const MEDICAL_FOLDER_MANAGEMNT = "evax_ii.evaxii.medical_folder_management";

export const ROUTINE = "routine"

export const OCCASIONAL = "occasional"

export const SEASONAL = "seasonal"

export const ANTIRABIQUE = "rabies"


// modules
export const CHILDREN = "children";

export const SUBSCRIPTION = "subscription"

export const VACCINATION = "vaccination";

export const ALLERGY = "allergy";

export const GROWTH_METRICS = "growth_metrics";

export const HEAD_CIRCUMFERENCE_METRICS = "head_circumference_metrics";
export const MEDICAL_FOLDER = "medical_folder";
export const DASHBOARD = "dashboard";
export const DCI = "dci";
export const CIRCONSCRIPTION = "circonscription";
export const CENTER = "center";
export const DIRECTOR = "director";
export const MAPI = "mapi";
export const SPECIALTIE = "specialtie";
export const REFERENTIEL = "referentiel";
export const USERS = "users";
export const GROUPS = "groups";
export const LOT = "lot";
