import { useFormikContext } from "formik"
import React from "react"
import useHideAndShowField from "./hooks/useHideAndShowField"


const BorderBottom = ({
  inputGroupClassName = "form-group",
  field, // { name, value, onChange, onBlur }
  size = 12,
  ...props
}) => {
  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  return (
    <div className={ inputGroupClassName  + conditionalShowAndHideClassName + ` col-lg-${size}`}>
      <hr  className="new5"/>
    </div>
  )
}

export default BorderBottom
{/* <div style={{size : "25px", color : "#7C7C7C"}}><FormattedMessage id="VACCINE" /></div> */}