import * as Yup from "yup";

import {
  INPUT,CHECKBOX,TABLE_OF_ITEMS
} from "../../../../../../../components/partials";

export const listNameFieldsAr = ({ intl }) => [
  {
    name: "nameAr",
    component: INPUT,
    label: intl.formatMessage({ id: "LIST.INPUT.NAME" }),
    placeholder: intl.formatMessage({ id: "LIST.INPUT.NAME" }),
    size: 12,
    validation: Yup.string().required(),
    disabled : true
  },
];

export const listNameFieldsFr = ({ intl }) => [
  {
    name: "nameFr",
    component: INPUT,
    label: intl.formatMessage({ id: "LIST.INPUT.NAME" }),
    placeholder: intl.formatMessage({ id: "LIST.INPUT.NAME" }),
    size: 12,
    validation: Yup.string().required(),
    disabled : true
  }
];


// Validation schema
export const listFieldsAr = ({ intl }) => [
  /*  {
     name: "optionSet[].rank",
     component: INPUT,
     label: intl.formatMessage({ id: "LIST.INPUT.ORDRE" }),
     placeholder: intl.formatMessage({ id: "LIST.INPUT.ORDRE" }),
     size: 2,
     validation: Yup.number().required(),
     disabled: true,
   }, */
  {
    name: "addedOptionSet[].valueAr",
    component: INPUT,
    label: intl.formatMessage({ id: "LIST.INPUT.OPTION" }),
    placeholder: intl.formatMessage({ id: "LIST.INPUT.OPTION" }),
    size: 12,
    validation: Yup.string().required(),
  },
];


export const listFieldsFr = ({ intl }) => [
  /*  {
     name: "optionSet[].rank",
     component: INPUT,
     label: intl.formatMessage({ id: "LIST.INPUT.ORDRE" }),
     placeholder: intl.formatMessage({ id: "LIST.INPUT.ORDRE" }),
     size: 2,
     validation: Yup.number().required(),
     disabled: true,
   }, */
  {
    name: "addedOptionSet[].valueFr",
    component: INPUT,
    label: intl.formatMessage({ id: "LIST.INPUT.OPTION" }),
    placeholder: intl.formatMessage({ id: "LIST.INPUT.OPTION" }),
    size: 9,
    validation: Yup.string().required(),
  },
  {
    name: "addedOptionSet[].active",
    component: CHECKBOX,
    options: [
      { value: true, label: intl.formatMessage({ id: "INPUT.LIST_OPTION.ACTIVE" }) }
    ],
    initialValue: true,
    defaultValue: false,
    checkboxSize: "md",
    size: 3,
  },
];


export const defaultlistFieldsAr = ({ intl }) => [
  {
      name: "defaultOptionSet",
      showActions: false,
      showSearchField:false,
      columns: [
          {
              dataField: "rank",
              text: intl.formatMessage({
                  id: "LIST.INPUT.ORDRE",
              }),
              size: 2,
          },
          {
              dataField: "valueAr",
              text: intl.formatMessage({
                  id: "LIST.INPUT.OPTION",
              }),
              size: 10,
          },
      ],
      component: TABLE_OF_ITEMS,
      
      size: 12,
  },
];


export const defaultlistFieldsFr = ({ intl }) => [
  {
      name: "defaultOptionSet",
      showActions: false,
      showSearchField:false,
      columns: [
          {
              dataField: "rank",
              text: intl.formatMessage({
                  id: "LIST.INPUT.ORDRE",
              }),
              size: 2,
          },
          {
              dataField: "valueFr",
              text: intl.formatMessage({
                  id: "LIST.INPUT.OPTION",
              }),
              size: 10,
          },
      ],
      component: TABLE_OF_ITEMS,
      size: 12,
  },
];