import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import Dropzone from "react-dropzone-uploader";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import Preview from "./dropzone/Preview";
import useHideAndShowField from "./hooks/useHideAndShowField";
import uuid from "uuid";
import { convertUrlToImageData, toAbsoluteUrl } from "src/helpers";
import SVG from "react-inlinesvg";
import { isRLTLang } from "src/i18n";
import { FormattedError } from "../../alerts/FormattedError";
import { FormattedMessage } from "react-intl";

const buttonStyle = {
  display: "flex",
  color: "white",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: ".5rem .625rem",
  elevation: "0",
  outline: "none",
  gap: ".5rem",
  width: "7.625rem",
  height: "2.5rem",
  background: "#1B4883",
  borderRadius: isRLTLang()
    ? ".25rem 0rem 0rem .25rem"
    : "0rem .25rem .25rem 0rem",
};

let key = null;

const Uploader = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  maxFiles = 1,
  multiple = false,
  accept,
  formatter = (value) => value.substring(value.lastIndexOf("/") + 1),
  required = false,
  autoUpload = true,
  repeater = false,
  size = 12,
  ...props
}) => {
  const formik = useFormikContext();
  const [editable, setEditable] = useState(false);
  const fieldCSSClasses = useFieldCSSClasses(
    form.touched,
    form.errors,
    field.name
  );
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });
  const [initFiles, setInitFiles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [elements, setElements] = useState([]);
  
  console.log("test",document.getElementsByClassName('dzu-dropzone').length)  
  
  useEffect(() => {
    console.log("Field:", field);
    if (field.value instanceof File) {
      setInitFiles([field.value]);
    } else if (_.isString(field.value) && !_.isEmpty(field.value)) {
      key = uuid();
      setEditable(true);
      convertUrlToImageData(field.value)
        .then((value) => {
          setInitFiles([value]);
        })
        .catch(() => {
          setInitFiles([
            new File(
              [],
              field.value.substring(field.value.lastIndexOf("/") + 1)
            ),
          ]);
        });
    }
  }, [field.value]);

  useEffect(() => {
    if (field.value === "" && !editable) {
      key = uuid();
    }
    // eslint-disable-next-line
  }, [formik.submitCount && field.value]);

  const handleChangeStatus = ({ file, meta }, status) => {
    console.log("Status:", status);
    setError(false);
    if (status === "done" && meta.size > 0) {
      console.log({field})
      form.setFieldValue(field.name, file);
      setDisabled(true);
    }
    if (status === "rejected_file_type") {
      console.log({field})

      setError("ERROR.REJECTED_FILE_TYPE");
    }
    if (status === "removed") {
      console.log({field})

      form.setFieldValue(field.name, undefined);
      setDisabled(false);
    }
  };

  return (
    <div
      className={`form-group ${fieldCSSClasses} ${conditionalShowAndHideClassName} col-lg-${size}`}
    >
      {label && (
        <label className="custom-label-style">
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "0rem",
        }}
      >
        <Dropzone
          accept={accept}
          key={key}
          onChangeStatus={handleChangeStatus}
          maxFiles={maxFiles}
          multiple={multiple}
          initialFiles={initFiles}
          onSubmit={(files, allFiles) => console.log(files, allFiles)}
          PreviewComponent={Preview}
          inputContent={""}
          inputWithFilesContent={""}
          autoUpload={autoUpload}
        />
        <button
          disabled={disabled}
          type="button"
          className="btn"
          style={buttonStyle}
          onClick={() => {
            const [name,index] = field.name.split('.');
            if(repeater && index) {
              const dropzoneElement = document.getElementsByClassName('dzu-dropzone')[parseInt(index)+1];
              dropzoneElement.querySelector('input').click()
            }else {
              const dropzoneElement = document.getElementsByClassName('dzu-dropzone')[0];
              dropzoneElement.querySelector('input').click()
            }
          }}
        >
          <SVG src={toAbsoluteUrl("/media/svg/attach.svg")} size="3em" />
          <div className="fontSize-12">
            <FormattedMessage id="GENERAL.ATTACH" />
          </div>
        </button>
      </div>
      {error && <FormattedError message={error} onClose={null} />}
    </div>
  );
};

export default Uploader;
